<template>
  <div class="operating-item d-flex justify-content-between cursor-pointer" @click="toggleActive">
    <div class="name d-flex align-items-center flex-nowrap">
      <Icon class="icon" name="Date" size="24" />
      <div class="fs-h5" v-text="name"></div>
    </div>
    <div class="info d-flex align-items-center flex-nowrap">
      <div class="common-select fs-title-16">
        <span>{{selectName}}</span>
        <div :class="['select-options' ,'custom-z-index' ,selectActive ? 'select-active' : '']">
          <div class="select-item fs-body-16 text-center" v-for="(item) in options" :key="item.index" @click="updateValue(item)">
            <span v-text="item.name"></span>
          </div>
        </div>
      </div>
      <Icon class="icon d-none d-md-block" name="edit" size="24" />
    </div>
  </div>
</template>

<script>
import svgEdit from "@/assets/images/icon/edit-gray.svg";

export default {
  name: 'JourneyDaySelectItem',
  data() {
    return {
      // selectName: '',
      selectValue: this.value,
      selectActive: false,
      imageSrc: {
        svgEdit: svgEdit,
      },
    };
  },
  props: {
    icon: {
      required: true,
    },
    name: {
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      required: false,
      default: '',
    }
  },
  components: {
  },
  computed: {
    selectName() {
      const matchingItem = this.options.find(item => item.val === this.value);
      return matchingItem ? matchingItem.name : '';
    },
	},
  watch: {
    // options(newVal) {
    //   const matchingItem = newVal.find(item => item.val === this.value);
    //   this.selectName = matchingItem ? matchingItem.name : '';
    // },
    selectValue(newVal) {
      this.$emit('input', newVal);
    },
  },
  mounted() {
    
	},
  methods: {
    updateValue(item) {
      this.selectValue = item.val;
      // this.selectName = item.name;
    },
    toggleActive() {
      if (this.selectActive === false) {
        this.show();
      } else {
        this.hide();
      }
      this.selectActive = !this.selectActive;
    },
    show() {
      document.addEventListener('click', this.hidePanel, false);
    },
    hide() {
      document.removeEventListener('click', this.hidePanel, false);
    },
    hidePanel(e) {
      if (!this.$el.contains(e.target)) {
        this.selectActive = false;
        this.hide();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
  .common-select {
    position: relative;
    .select-options {
      cursor: pointer;
      display: none;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: calc(100% + 4px);
      right: 0;
      min-width: 100px;
      background: $color-white;
      border-radius: 4px;
      box-shadow: 0 4px 36px $color-black-400;
      overflow: hidden;
      &.select-active {
        display: flex;
      }
      .select-item {
        cursor: pointer;
        width: 100%;
        padding: 12px;
        &:not(:last-child) {
          box-shadow: inset 0px -1px 0px $color-accent;
        }
        &:hover span {
          transform: scale(1.05);
        }
      }
    }
  }
  .operating-item {
    .flex-info {

    }
  }
</style>
